.chat-container {
    /* max-width: 600px; */
    width: auto;
    margin: auto;
    background: antiquewhite;
    padding: 2em;
    border-radius: 12px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.messages {
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 1.5em;
    border-radius: 8px;
}

.input-area {
    display: flex;
    gap: 10px;
}

.input-text-area {
    width: 500px;
    border-radius: 1px;
}

input,
button {
    padding: 10px;
    border-radius: 8px;
    flex: 1;
    /* This ensures that they take equal width */
}

button {
    background-color: #1A2037;
    color: #faebd7;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* button:hover {
    background-color: #151a2b;
} */

.user,
.teacher {
    padding: 10px;
    margin: 5px 0;
    border-radius: 8px;
}

.user {
    text-align: right;
    color: blue;
    background-color: #e6f7ff;
}

.teacher {
    text-align: left;
    color: green;
    background-color: #e6ffe6;
}

.file-dropdown {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    cursor: pointer;
}

.file-dropdown p {
    padding: 12px 16px;
    display: block;
}

.confirm-file {
    margin-top: 10px;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
}

.confirm-file button {
    margin-left: 10px;
}

.ai-typing {
    padding: 10px;
    font-style: italic;
    color: #888;
}

.view-card-button {
    background-color: #4CAF50;
    /* Green */
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    width: 20px;
    display: block;
}

.inline-card {
    margin-top: 10px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
}

.chat-page {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px;
    max-width: 50vw;
    width: 40vw;
}

.full-container {
    display: flex;
    flex-direction: column;
}

.small-container {
    display: flex;
    flex-direction: row;
}

.chat-container {
    flex: 1;
}


.timeline .card.current {
    background-color: lightblue;
    /* ... other styles for current card */
    /* border-radius: 4px;
    padding: 5px; */
}

.timeline {
    margin-top: 20px;
    margin-left: 20px;
    width: 100px;
}

.card.current:hover {
    background-color: antiquewhite;
    transition: 1s;
}

.card {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 20px;
}

.chat-title {
    font-size: 3.4em;
    padding-left: 32px;
}

.fileupload-icon {
    width: 50px;
    height: 50px;
}

.timeline {
    max-height: 500px;
    /* Adjust as per your design */
    overflow-y: auto;
    width: 100%;
    /* Adjust as per your design */
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.card {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
}

.card.current {
    background-color: #e0e0e0;
    /* Adjust as per your design */
}

.attempts {
    display: flex;
    gap: 5px;
}

.stick {
    width: 5px;
    height: 20px;
    background-color: #ccc;
    /* Default/fallback color */
}

.stick.red {
    background-color: red;
}

.stick.yellow {
    background-color: yellow;
}

.stick.green {
    background-color: green;
}


.bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;
}

.bottom-left {
    margin-left: 20px;
}

.bottom-right {
    display: flex;
    flex-direction: row;
    width: 100px;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
