.profile-container {
    max-width: 800px;
    margin: 80px auto;
    padding: 40px;
    background: white;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    border-radius: 12px;
    font-family: 'Helvetica Neue', sans-serif;
}

.profile-container h2 {
    color: #1A2037;
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
}

.plans-grid {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.plan-card {
    flex: 1;
    padding: 20px;
    border-radius: 12px;
    background: #f6f6f6;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    cursor: pointer;
    transition: transform 0.2s;
}

.plan-card:hover,
.plan-card.selected {
    transform: scale(1.05);
}

.plan-card h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
}

.plan-card p {
    font-size: 1.2rem;
    margin-bottom: 10px;
    text-align: center;
}

.buy-button {
    display: block;
    width: 100%;
    padding: 10px 20px;
    background: black;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.2s;
    font-size: 1.3rem;
}
