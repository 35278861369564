.anki-card-preview {
    width: 180px;  /* Increased width */
    height: 240px; /* Increased height */
    border: 1px solid #e0e0e0;
    margin: 15px;
    padding: 15px; /* Increased padding */
    font-size: 0.8rem;
    display: inline-block;
    border-radius: 20px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1); /* Added shadow for depth */
    transition: transform 0.3s ease; /* Animation on hover */
}

.anki-card-preview:hover {
    transform: translateY(-5px);
}

.anki-paragraph-preview {
    margin-bottom: 10px; /* Increased margin */
    font-weight: 600; /* Bold the text */
}

.anki-card-text-preview {
    height: 80%;
    overflow: hidden;
    line-height: 1.4; /* Improved line spacing */
}

.anki-question-preview,
.anki-answer-preview {
    margin-bottom: 10px; /* Increased margin */
}

.anki-question-preview {
    margin-top: 10px;
}

.anki-answer-preview {
    color: #555; /* Slightly muted answer color */
}

.file-view-container {
    padding: 30px; /* Increased padding */
}

.cards-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 30px; /* Increased gap */
    margin-bottom: 30px; /* Increased margin */
}

.pagination-controls {
    display: flex;
    justify-content: center; /* Centered pagination */
    align-items: center;
    gap: 20px; /* Added space between elements */
}

/* button {
    padding: 10px 15px;
    border: none;
    border-radius: 12px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    height: 5em;
    transition: background-color 0.3s ease; 
} */


button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
