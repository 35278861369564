.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin: 2em;
}

.nav a {
  text-decoration: none;
  color: #1A2037;
  font-weight: 600;
  padding: 8px 16px;
  transition: color 0.2s;
}

.nav a:hover {
  color: #007BFF;
}

/* Styles for larger devices */
@media (min-width: 768px) {
  .nav {
    position: fixed;
    top: 1em;
    left: 1em;
    margin: 0;
  }

  body {
    height: 100vh;
    align-items: center;
  }
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  background: rgba(250, 235, 215, 0.22);
  font-family: 'Avenir', 'Helvetica Neue', 'Arial', sans-serif;
}