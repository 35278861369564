

.Auth-form-container {
    background: antiquewhite;
    padding: 2em;
    border-radius: 12px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 30vw;
}

.Auth-form-title {
    font-size: 2em;
    margin-bottom: 1em;
    color: #1A2037;
}

.form-group {
    margin-bottom: 1.5em;
}

label {
    display: block;
    margin-bottom: 0.5em;
    color: #1A2037;
}

.form-control {
    width: 100%;
    padding: 0.5em;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-top: 0.5em;
}

.btn-primary {
    
    background-color: #1A2037;
    color: antiquewhite;
    padding: 0.5em 1em;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.d-grid {
    display: grid;
}

.gap-2 {
    gap: 1em;
}

@media (max-width: 768px) {
    .Auth-form-container {
      width: 80vw;
      margin: auto;
  }
  
  }