.filechooser-container {
    font-family: 'Arial', sans-serif;
    height: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    background: #f7f8fc;
    margin: auto;
    width: 50vw;
}

h2 {
    margin-bottom: 15px;
    color: #333;
}

.files-old-and-new {
    display: flex;
    gap: 20px;
    align-items: stretch;
    margin-bottom: 20px;
}

.existing-files {
    flex: 1;
    padding-right: 10px;
    border-right: 1px solid #ddd;
}

.filechooser-grid {
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.filechooser-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    padding: 15px;
    transition: transform 0.2s ease-in-out;
}

.filechooser-item:hover {
    transform: scale(1.05);
}

.filechooser-icon {
    width: 50px;
    height: 50px;
}

.filechooser-filename {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
}

.dropzone {
    flex: 1;
    border: 2px dashed #ccc;
    background: #f7f8fc;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-height: 200px;
}

.fileupload-buttons-start {
    display: flex;
    gap: 1em;
    align-items: center;
    width: 100%;
}

button {
    padding: 10px 20px;
    border-radius: 12px;
    border: none;
    color: #fff;
    cursor: pointer;
    max-width: 200px;
    transition: background-color 0.2s ease-in-out;
}

button:disabled {
    background-color: #8e8686;
    cursor: not-allowed;
}

.notification {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    text-align: center;
}

.notification.warning {
    background-color: #ffcc00;
    color: black;
}

.notification.error {
    background-color: #b86464;
    color: white;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
    .files-old-and-new {
        flex-direction: column;
    }
    
    .existing-files {
        border-right: none;
        padding-right: 0;
    }

    .filechooser-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .fileupload-buttons-start {
        flex-direction: column;
        align-items: stretch;
    }

    .filechooser-container {
        width: 80vw;
    }
}

.filechooser-item.not-processed {
    opacity: 0.5;
    pointer-events: none; /* Makes it non-clickable */
}

.processing-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,0.7);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
}

/* To ensure the Practice Online button remains disabled for non-processed files */
.filechooser-item.not-processed + button {
    background-color: #8e8686;
    cursor: not-allowed;
}
