body {
    margin: 0;
    padding: 0;
    background: rgba(250, 235, 215, 0.22);
}

.anki-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Times New Roman', Times, serif;
}

.anki-title {
    font-size: 4em;
    margin-bottom: 0.5em;
}

.anki-items {
    width: 50vw;
}

.anki-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.anki-red {
    background: rgba(240, 128, 128, 0.42) !important;
}

.anki-yellow {
    background: rgba(236, 224, 90, 0.39) !important;
}

.anki-green {
    background: rgba(144, 238, 144, 0.65) !important;
}

.anki-card-text { 
    font-size: 15px;
    margin-bottom: 1em;
}

.anki-margin {
    margin-top: 1em;
    margin-bottom: 1em;
}

.anki-button, .anki-input {
    border: #1A2037;
    background: antiquewhite;
    width: 10em;
    height: 4em;
    border-radius: 12px;
    color: #1A2037;
    padding: 0;
    margin-right: 1.5em;
}

.anki-spoiler {
    opacity: 3%;
}

.anki-paragraph {
    font-size: 15px;
}


.anki-question {
margin-bottom: 1em;
}

