/* Report.css */
.report {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    margin: 20px auto;

}

.report h2 {
    color: #333;
    margin-bottom: 10px;
}

.report .encouragement {
    font-style: italic;
    color: #555;
    margin-bottom: 20px;
}

.report .statistics p {
    color: #555;
    margin: 5px 0;
}

.report h3 {
    color: #333;
    margin-top: 20px;
}

.report ul {
    list-style-type: none;
    padding: 0;
}

.report li {
    color: #555;
    margin: 5px 0;
}

.report li span {
    font-weight: bold;
    color: #e44d26;
}
